@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/typography';

.jsondump {
  font-size: 10px;
}

.progress-table {
  border: 1px solid $lightGray;
  font-size: 10px;
  max-height: 300px;
  overflow: scroll;

  .updated,
  .fulfilled {
    background-color: $lightGreen;
  }

  .skipped {
    background-color: $lightYellow;
  }

  .task {
    @include bold;
  }

  .bonus {
    color: $lighterGray;
  }
}
