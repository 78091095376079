@import '$extensionSrc/sass/consts';

.MetricsKpis {
  display: flex;
  flex-direction: column;
  gap: 2 * $spacer;
}

.stats {
  display: flex;
  flex-direction: row;
  gap: 3 * $spacer;
  justify-content: center;
}

.subheader {
  margin-left: 38px;
}

.MetricsBox {
  align-items: center;
  border: 1px solid $black;
  display: flex;
  flex-direction: column;
  gap: 2 * $spacer;
  height: 185px;
  justify-content: center;
  padding: 2 * $spacer;
  width: 185px;
}

.RateBox {
  align-items: center;
  border: 1px solid $black;
  display: flex;
  flex-direction: column;
  gap: 2 * $spacer;
  justify-content: center;
  padding: 2 * $spacer;
  width: 394px;
}
